<template>
<div>
<b-row class="match-height">
    <b-col md="5" lg="5" >
        <b-carousel id="carousel-example-generic" controls indicators :interval="0">          
          <b-carousel-slide v-if="project.projectVideoUrl">
            <template #img>
              <iframe :src="project.projectVideoUrl" class="sosty-project-details-video" frameborder="0" style="max-height:700px" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </template>
          </b-carousel-slide>
          <b-carousel-slide v-if="project.projectImageUrl1">
            <template #img>
              <b-img :src="project.projectImageUrl1" class="sosty-project-details-image" fluid alt="Responsive image" style="max-height:700px" />
            </template>
          </b-carousel-slide>
          <b-carousel-slide v-if="project.projectImageUrl2">
            <template #img>
              <b-img :src="project.projectImageUrl2" class="sosty-project-details-image" fluid alt="Responsive image" style="max-height:700px" />
            </template>
          </b-carousel-slide>
          <b-carousel-slide v-if="project.projectImageUrl3">
            <template #img>
              <b-img :src="project.projectImageUrl3" class="sosty-project-details-image" fluid alt="Responsive image" style="max-height:700px" />
            </template>
          </b-carousel-slide>
          <b-carousel-slide v-if="project.projectImageUrl4">
            <template #img>
              <b-img :src="project.projectImageUrl4" class="sosty-project-details-image" fluid alt="Responsive image" style="max-height:700px" />
            </template>
          </b-carousel-slide>
        </b-carousel>
    </b-col>

    <b-col md="7" lg="7">      
      <b-card >        
        <b-card-title>
          <b-row class="mb-0">
            <b-col>
                <h3>{{project.projectName}} ({{project.projectCode}})</h3>
                <h6 style="opacity:0.6">Ubicación: {{project.locationAddress}} </h6>
            </b-col>
            <b-col> 
                <b-row style="align-items:center;">  
                    <b-col style="text-align:right; padding-right:25px">
                        <h6 style="font-size:12px; color:#00bd56;">Póliza de seguro
                            <b-avatar size="24" variant="light-primary">
                                <feather-icon size="18" icon="ShieldIcon"/>
                            </b-avatar>  
                        </h6>                              
                    </b-col>
                </b-row>

                <b-row style="align-items:center;">
                    <b-col style="text-align:right;padding-right:25px">                                     
                        <h6 style="font-size:12px; color:#00bd56;">Monitoreo Sosty
                            <b-avatar  size="24" variant="light-primary">
                                <feather-icon size="18" icon="FileIcon"/>
                            </b-avatar>   
                        </h6>                    
                    </b-col>         
                </b-row>
            </b-col>
          </b-row>
        </b-card-title>

      <b-card-body class="statistics-body" style="padding:0px 21px 10px 21px">
        <b-row class="mt-1">
            <b-col md="4" lg="4" sm="4" class="mb-2 mb-md-0 mb-2 mb-xl-0">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-primary">
                      <feather-icon size="24" icon="BarChartIcon"/>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">{{(project.projectProfitability)}} % (E.A)</h4>
                    <b-card-text class="font-small-3 mb-0">Rentabilidad Estimada*</b-card-text>
                  </b-media-body>
                </b-media>
            </b-col>
            <b-col md="4" lg="4" sm="4" class="mb-2 mb-md-0 mb-2 mb-xl-0 ">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-primary">
                      <feather-icon size="24" icon="DollarSignIcon"/>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">${{parseFloat(this.minimalInvestment).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} COP</h4>
                    <b-card-text class="font-small-3 mb-0">Inversión mínima</b-card-text>
                  </b-media-body>
                </b-media>
            </b-col>
            <b-col md="4" lg="4" sm="4" class="mb-2 mb-md-0 mb-2 mb-xl-0">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-primary">
                      <feather-icon size="24" icon="UsersIcon"/>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">{{project.amountOfInvestors}}</h4>
                    <b-card-text class="font-small-3 mb-0">NeoGanaderos</b-card-text>
                  </b-media-body>
                </b-media>
            </b-col>            
            <b-col md="4" lg="4" sm="4" class="mb-2 mb-md-0 mb-2 mb-xl-0 mt-1">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-primary">
                      <feather-icon size="24" icon="CalendarIcon"/>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">{{project.projectDuration}} Meses</h4>
                    <b-card-text class="font-small-3 mb-0">Duración</b-card-text>
                  </b-media-body>
                </b-media>
            </b-col>
            <b-col md="4" lg="4" sm="4" class="mb-2 mb-md-0 mb-2 mb-xl-0 mt-1">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-primary">
                      <feather-icon size="24" icon="CalendarIcon"/>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">{{project.daysLeft}} días</h4>
                    <b-card-text class="font-small-3 mb-0">Finalización Recaudo</b-card-text>
                  </b-media-body>
                </b-media>
            </b-col>            
            <b-col md="4" lg="4" sm="4" class="mb-2 mb-md-0 mb-2 mb-xl-0 mt-1">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-primary">
                      <feather-icon size="24" icon="TagIcon"/>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0" v-if="project.projectType == 'Levante' || project.projectType == 'Ceba'">{{project.amountOfCattles}}</h4>
                    <b-card-text class="font-small-3 mb-0" v-if="project.projectType == 'Levante' || project.projectType == 'Ceba'">Cantidad de Animales</b-card-text>
                    <h4 class="font-weight-bolder mb-0" v-if="project.projectType == 'Cria'">{{project.amountOfBulls}} - {{project.amountOfCows}}</h4>
                    <b-card-text class="font-small-3 mb-0" v-if="project.projectType == 'Cria'">Toros - Vacas</b-card-text>
                  </b-media-body>
                </b-media>
            </b-col>
        </b-row>
        
        <b-row>
            <b-col md="12" lg="12">
                <b-row class="mt-2" v-if="project.projectType == 'Levante' || project.projectType == 'Ceba'">
                    <b-col md="6" lg="6">
                        <b-card-text class="mb-50"><b>Actual: </b>{{currentAmountOfCattles}} Animales ({{parseFloat(project.investmentCollected).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} Kg)</b-card-text>
                    </b-col>
                    <b-col md="6" lg="6" class="text-right">
                        <b-card-text class="mb-50"><b>Meta: </b>{{project.amountOfCattles}} Animales ({{parseFloat(project.investmentRequired).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} Kg)</b-card-text>
                    </b-col>
                </b-row>

                <b-row class="mt-2" v-if="project.projectType == 'Cria'">
                    <b-col md="6" lg="6">
                        <b-card-text class="mb-50"><b>Actual: </b>{{parseFloat(project.investmentCollected).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} Sostycs</b-card-text>
                    </b-col>
                    <b-col md="6" lg="6" class="text-right">
                        <b-card-text class="mb-50"><b>Meta: </b>{{parseFloat(project.investmentRequired).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} Sostycs</b-card-text>
                    </b-col>
                </b-row>                
                
                <b-progress animated v-if="this.minBar>5 && (project.projectType == 'Levante' || project.projectType == 'Ceba')" striped :max="max" height="20px">
                  <b-progress-bar animated variant="primary" :value="project.projectProgres"></b-progress-bar>                  
                  <b-progress-bar animated variant="warning" :value="this.minBar" >min</b-progress-bar>                  
                </b-progress>

                <b-progress animated v-else-if="this.minBar > 0 && this.minBar <= 5  && (project.projectType == 'Levante' || project.projectType == 'Ceba')" striped :max="max" height="20px">
                  <b-progress-bar animated variant="primary" :value="project.projectProgres"></b-progress-bar>                  
                  <b-progress-bar animated variant="warning" :value="this.minBar" ></b-progress-bar>                  
                </b-progress>

                <b-progress animated v-else striped :max="max" height="20px">
                  <b-progress-bar animated variant="primary" :value="project.projectProgres"></b-progress-bar>
                </b-progress>                
                <b-card-text class="mb-50">{{ ((parseFloat(project.investmentCollected)/parseFloat(project.investmentRequired))*100).toFixed(2) }} % Recaudado</b-card-text>

            </b-col>
        </b-row>

        <b-row v-if="this.project.projectProgres < this.minRequiredToStart  && (project.projectType == 'Levante' || project.projectType == 'Ceba')">      
          <b-col md="12" lg="12">
              <b-alert variant="info" class="mb-0" show>
                <div style="cursor:pointer;" b-tooltip.hover title="En caso de no completar el mínimo, te devolvemos el dinero" class="alert-body font-small-1">
                  <span style="font-size:14px"><strong>Ayúdanos a completar el mínimo para comenzar el proyecto</strong></span>
                </div>
              </b-alert>
          </b-col>
        </b-row>

        <b-row v-else-if="(this.project.projectProgres < this.minRequiredToStart || this.project.projectProgres < 100) && (project.projectType == 'Levante' || project.projectType == 'Ceba')">
          <b-col>
              <b-alert variant="warning" show>
                <div class="alert-body font-small-1">
                    <span style="font-size:14px">
                      <strong>
                        En caso de no completar el 100% se comprarán los {{Math.round(currentAmountOfCattles)}} animales actuales y la rentabilidad puede variar un poco
                      </strong>
                    </span>
                </div>
              </b-alert>
          </b-col>
        </b-row> 

        <b-col v-if="!projectHaveReachedInvestment">
          <b-col v-if="!isBlockedForInvestment"  class="d-block d-sm-block d-md-none">
              <div v-if="userType=='Productor'"  class="mt-1" md="6" lg="6">
                  <b-button variant="primary" v-b-modal.modal-enable-investor-profile block>Participar</b-button> 
              </div>
              <div v-if="userType!='Productor'" class="mt-1" md="6" lg="6">
                  <b-button variant="primary" @click="goToInvest" block>Participar</b-button> 
              </div>
            </b-col>
            <b-col v-else-if="isBlockedForInvestment"  class="d-block d-sm-block d-md-none">
              <div v-if="userType=='Productor'" class="mt-1" md="6" lg="6">
                  <b-button variant="primary" v-b-modal.modal-enable-investor-profile block disabled>Participar</b-button> 
              </div>
              <div v-if="userType!='Productor'" class="mt-1" md="6" lg="6">
                  <b-button variant="primary" @click="goToInvest" block disabled>Participar</b-button> 
              </div>
              <div v-else-if="!isLoggedIn && !projectHaveReachedInvestment" class="d-block d-sm-block d-md-none">
                <b-button variant="primary" @click="goToInvest" block disabled>Participar</b-button>
              </div>
            </b-col>            
            <b-col class="d-block d-sm-block d-md-none mt-1">
              <div>
                <b-button variant="danger"  @click="showRisksModal()" block>¿Que riesgos tiene?</b-button>
              </div>              
            </b-col>
          </b-col>

        <b-row v-if="projectHaveReachedInvestment" class="mt-3">      
            <b-col md="12" lg="12">              
                <b-alert variant="warning" show>
                  <div class="alert-body">
                    <span><strong>Hemos completado el 100% de este proyecto! Te invitamos a estar atento a nuestros próximos proyectos.</strong></span>
                  </div>
                </b-alert>
            </b-col>
        </b-row>  

        <b-modal id="login-register" hide-footer title="Que deseas hacer?">
            <b-button class="mt-1" variant="primary" block @click="goToLoginWithRedirection">
                <feather-icon icon="LogInIcon" class="mr-50"/>
                <span class="align-middle">Usar mi cuenta para Participar</span>
            </b-button>        
            <b-button class="mt-2" variant="outline-primary" block @click="goToRegisterWithRedirection">
                <feather-icon icon="UserPlusIcon" class="mr-50"/>
                <span class="align-middle">Crear cuenta nueva</span>
            </b-button>
        </b-modal>

        <b-modal ref="modal-risk" hide-footer centered title="Riesgos">
          <risks-modal-component></risks-modal-component>
          <b-row class="mb-1 mt-2">
            <b-col md="12" lg="12">
                <b-button size="lg" variant="primary" block @click="hideRisksModal">Aceptar</b-button>
            </b-col>
          </b-row>
        </b-modal>

        <b-modal id="modal-enable-investor-profile" hide-footer centered title="Participar">
          <b-card-text> 
             <b-row>
                <b-col md="12" xl="12" class="mb-1">
                    <b-form-group>                    
                      <span class="align-middle">Actualmente eres un Productor Sosty, quieres habilitar la funcionalidad de NeoGanadero?</span>
                    </b-form-group>
                </b-col>                                  
            </b-row>            
            <b-button class="mt-1" @click="enableInvestorProfileForProducer" variant="primary" block >                
                <span class="align-middle">Si</span>
            </b-button> 
            <b-button class="mt-1" variant="secondary" @click="$bvModal.hide('modal-enable-investor-profile')" block>                
                <span class="align-middle">No</span>
            </b-button> 
          </b-card-text>
        </b-modal>

      </b-card-body>
      </b-card>

      <b-card v-if="!projectHaveReachedInvestment" >
        <b-card-body class="statistics-body" style="padding:0px 21px 0px 21px">
          <b-row  > 
            <b-col md="12" lg="12" xl="5" style="padding-top:6px">
                <span style="color:#00bd56; font-size:16px;margin-right:0;">
                  <strong> Simula tu Participación, digita el valor:</strong>
                </span>
            </b-col>          

            <b-col md="12" lg="12" xl="3" style="padding-left:0">
              <b-input-group >
                <b-input-group-prepend is-text style="padding-top:8px">
                  <feather-icon size="18" icon="DollarSignIcon" style="color:#00bd56" />
                </b-input-group-prepend>
                <cleave 
                  style="margin-left:0;margin-bottom:10px"                        
                  id="vi-first-name"
                  v-model.number="simulateTotalToInvest"
                  value-field="simulateTotalToInvest"
                  class="form-control "
                  :options="options.number" 
                  placeholder="Mín $700.000"                           
                  v-on:input="simulateInvestmentResults"                          
                  ref="myTextField"/>
                </b-input-group>
            </b-col>

            <b-col class="d-none d-sm-none d-md-block" xl="4">              
              <b-button variant="danger"  @click="showRisksModal()" block>                                                                                        
                ¿Que riesgos tiene?
              </b-button>
            </b-col>
          </b-row>

          <b-row > 
            <b-col md="12" lg="12" xl="8" class="mt-1">
              <b-row v-if="simulateTotalToInvest<this.minimalInvestment"  >
                <b-col md="12" lg="12">
                  <b-alert  variant="danger" show>
                    <div class="alert-body" >
                      <span><strong>La inversión mínima es $700.000</strong></span>
                    </div>
                  </b-alert>
                </b-col>
              </b-row>

              <b-row v-if="simulateTotalToInvest>=this.minimalInvestment" >
                <b-col md="12" lg="12">
                  <b-alert variant="warning" show>
                    <div class="alert-body" style="padding:0px 14px">
                      <b-row>
                        <span style="font-size:16px"><strong>Ganarás $ {{Math.round(simulateTotalToReceive - simulateTotalToInvest).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} COP, en {{project.projectDuration}} Meses aproximadamente, equivalentes a una rentabilidad total estimada del ({{totalGainPercent.toFixed(2)}}%).</strong></span>
                      </b-row>
                      <b-row>
                        <span style="font-size:10px"><strong>Recibirás aproximadamente $ {{Math.round(simulateTotalToReceive).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} COP, al momento de liquidar el proyecto.</strong></span>
                      </b-row> 
                    </div>                    
                  </b-alert>
                </b-col> 
              </b-row>
            </b-col>
          
            <b-col v-if="!isBlockedForInvestment"  class="d-none d-sm-none d-md-block" xl="4">
              <div v-if="userType=='Productor'"  class="mt-1" md="6" lg="6">
                  <b-button variant="primary" v-b-modal.modal-enable-investor-profile block>Participar</b-button> 
              </div>
              <div v-if="userType!='Productor'" class="mt-1" md="6" lg="6">
                  <b-button variant="primary" @click="goToInvest" block>Participar</b-button> 
              </div>
            </b-col>
            <b-col v-else-if="isBlockedForInvestment"  class="d-none d-sm-none d-md-block" xl="4">
              <div v-if="userType=='Productor'" class="mt-1" md="6" lg="6">
                  <b-button variant="primary" v-b-modal.modal-enable-investor-profile block disabled>Participar</b-button> 
              </div>
              <div v-if="userType!='Productor'" class="mt-1" md="6" lg="6">
                  <b-button variant="primary" @click="goToInvest" block disabled>Participar</b-button> 
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>


    </b-col>
  </b-row>

    <b-row class="match-height">     
      <b-col xl="2" md="4" sm="6" v-if="project.projectType == 'Levante' || project.projectType == 'Ceba'">
        <statistic-card-vertical statistic-title="Tamaño Lote" :statistic="project.amountOfCattles + ' Animales'" icon="GridIcon"  color="primary" />
      </b-col>      
      <b-col xl="2" md="4" sm="6" v-if="project.projectType == 'Levante' || project.projectType == 'Ceba'">
        <statistic-card-vertical statistic-title="Peso Inicial del Animal" :statistic="project.initialWeight + ' Kg'" icon="LayersIcon"  color="primary" />
      </b-col>  
      <b-col xl="2" md="4" sm="6" v-if="project.projectType == 'Levante' || project.projectType == 'Ceba'">
        <statistic-card-vertical statistic-title="Peso Final del Animal" :statistic="project.finalWeight + ' Kg'" icon="LayersIcon"  color="primary" />
      </b-col>  
      <b-col xl="2" md="4" sm="6" v-if="project.projectType == 'Levante' || project.projectType == 'Ceba'">
        <statistic-card-vertical statistic-title="Engorde Mensual" :statistic="(project.cattleWeightAverageGain*30)/1000 + ' Kg'" icon="CornerRightUpIcon"  color="primary"/>
      </b-col>

      <b-col xl="2" md="4" sm="6" v-if="project.projectType == 'Cria'">
        <statistic-card-vertical statistic-title="Para Vender" :statistic="project.amountOfCalvesToSell + ' Terneros'" icon="GridIcon"  color="primary" />
      </b-col>      
      <b-col xl="2" md="4" sm="6" v-if="project.projectType == 'Cria'">
        <statistic-card-vertical statistic-title="Peso al Destete" :statistic="project.calveWeigthAtWeaning + ' Kg'" icon="LayersIcon"  color="primary" />
      </b-col>
      <b-col xl="2" md="4" sm="6" v-if="project.projectType == 'Cria'">
        <statistic-card-vertical statistic-title="Crias Año / Vaca" :statistic="project.calvesPercentage + ' %'" icon="CornerRightUpIcon"  color="primary" />
      </b-col>
      <b-col xl="3" md="4" sm="6" v-if="project.projectType == 'Cria'">
        <statistic-card-vertical statistic-title="Ingresos Venta Terneros" :statistic="project.calvesRevenue/1000000 + ' Millones'" icon="DollarSignIcon"  color="primary" />
      </b-col>
      <b-col xl="3" md="4" sm="6" v-if="project.projectType == 'Cria'">
        <statistic-card-vertical statistic-title="Ingresos Liquidación" :statistic="project.liquidationRevenue/1000000 + ' Millones'" icon="DollarSignIcon"  color="primary" />
      </b-col>

      <b-col xl="4" md="4" sm="6" v-show="!isBreedingProject">
        <b-card class="card-tiny-line-stats" body-class="pb-50">
          <h5>Ganancia de Peso del Lote</h5>
          <!-- chart -->
          <vue-apex-charts ref="baseLineChart" height="120" :options="chartOptions" :series="series"/>
        </b-card>
      </b-col>      
    </b-row>

    <b-row class="match-height">
      <b-col xl="2" md="4" sm="6">
        <b-card no-body class="card-employee-task">
            <b-card-header>
                <b-card-title>Productor</b-card-title>
            </b-card-header>

            <!-- body -->
            <b-card-body>
            <div class="employee-task d-flex justify-content-between align-items-center">
                <b-media no-body @click="openProducerPublicProfile" class="sosty-pointer">
                    <b-media-aside>
                        <b-avatar rounded size="42" :src="project.producerProfilePictureUrl"/>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                        <h6 class="mb-0">
                        {{project.producerName}}
                        </h6>
                        <!-- <ul class="unstyled-list list-inline">
                            <li v-for="star in 5" :key="star" class="ratings-list-item">
                                <feather-icon icon="StarIcon" size="18" :class="[{'fill-current': star <= 4.5}, star <= 4.5 ? 'text-warning' : 'text-muted']"/>
                            </li>
                            <li>(4.5)</li>
                        </ul>                         -->
                    </b-media-body>
                </b-media>                  
            </div>
            <b>Productor Verificado</b>          
            </b-card-body>
        </b-card>
      </b-col>
     
      <b-col xl="4" md="4" sm="7">
        <b-card no-body class="card-employee-task">
            <b-card-header>
                <b-card-title>Predio</b-card-title>
            </b-card-header>

            <!-- body -->
            <b-card-body>
                <b-row class="match-height"> 
                    <b-col xl="6" md="6" sm="4">
                        <b>Ubicación:</b> {{project.locationAddress}}
                    </b-col>
                    <b-col xl="6" md="6" sm="4">
                        <b>Indicaciones:</b> {{project.locationArrivalLIndications}}
                    </b-col>    
                    <!-- <b-col xl="1" md="1" sm="4" @click="openPorjectLocationInMap('test')" class="sosty-pointer">
                        <b>Mapa:</b> 
                        <b-avatar variant="light-primary">
                            <feather-icon icon="MapPinIcon" />
                        </b-avatar>
                    </b-col>  -->
                </b-row>
            </b-card-body>
        </b-card>
      </b-col>   
      <b-col xl="2" md="4" sm="6">
        <statistic-card-vertical statistic-title="Tipo de Proyecto" :statistic="project.projectType" icon="Share2Icon"  color="primary" />        
      </b-col>
       <b-col xl="2" md="4" sm="6" v-if="project.projectType == 'Levante' || project.projectType == 'Ceba'">
        <statistic-card-vertical statistic-title="Sexo" :statistic="project.projectSex" icon="EyeIcon"  color="primary" />  
      </b-col>
      <b-col xl="2" md="4" sm="6">
        <statistic-card-vertical statistic-title="Tipo de Ganado" :statistic="project.projectCattleType" icon="SunIcon"  color="primary" />  
      </b-col>   
    </b-row>

    <b-row class="match-height">
      <b-col xl="4" md="6" sm="12">
        <b-card title="Detalle y Objetivos del Proyecto">
            <b-row class="match-height"> 
                <b-col xl="12" md="12" sm="12">
                    {{project.details}}
                </b-col> 
            </b-row>
        </b-card>
      </b-col>   
      <b-col xl="4" md="6" sm="12">
        <b-card title="Manejo de Riesgos">
            <b-row class="match-height"> 
                <b-col xl="12" md="12" sm="12">
                    {{project.risksManagement}}
                </b-col> 
            </b-row>
        </b-card>
      </b-col>   
      <b-col xl="4" md="6" sm="12">
        <b-card title="Plan de Comunicaciones">
            <b-row class="match-height"> 
                <b-col xl="12" md="12" sm="12">
                    {{project.communicationPlan}}
                </b-col> 
            </b-row>
        </b-card>
      </b-col>   
    </b-row>

    <b-row class="match-height">
      <b-col xl="12" md="12" sm="12">
        <b-card title="Historia del Proyecto">
            <b-row class="match-height"> 
                <b-col xl="12" md="12" sm="12">
                    {{project.projectStory}}
                </b-col> 
            </b-row>
        </b-card>
      </b-col>    
    </b-row>

    <b-row class="match-height">
      <b-col xl="12" md="12" sm="12">
        <b-card title="Documentos de Soporte">
            <b-card-body class="statistics-body">
                <b-row>
                    <b-col v-if="project.financialProjectionUrl" md="2" lg="2" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0">
                        <b-media no-body>
                        <b-media-aside class="mr-2">
                            <b-avatar class="b-avatar badge-light-primary rounded-circle" button @click="openDocument(project.financialProjectionUrl)" size="48" variant="light-primary">
                            <feather-icon size="24" icon="FileIcon"/>
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h5 class="font-weight-bolder mb-0">Proyeccion Financiera</h5>
                            <b-card-text @click="openDocument(project.financialProjectionUrl)" class="font-small-3 mb-0 sosty-pointer">Descargar</b-card-text>
                        </b-media-body>
                        </b-media>
                    </b-col>


                    <b-col v-if="project.libertadYTradicionCertificateUrl" md="2" lg="2" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0">
                        <b-media no-body>
                          <b-media-aside class="media-aside mr-2">
                              <b-avatar class="b-avatar badge-light-primary rounded-circle" button  @click="openDocument(project.libertadYTradicionCertificateUrl)" size="48" variant="light-primary" >
                              <feather-icon size="24" icon="FileIcon" />
                              </b-avatar>
                          </b-media-aside>
                          <b-media-body class="my-auto">
                              <h5 class="font-weight-bolder mb-0">Certificado de Libertad y Tradición</h5>
                              <b-card-text @click="openDocument(project.libertadYTradicionCertificateUrl)" class="font-small-3 mb-0 sosty-pointer">Descargar</b-card-text>
                          </b-media-body>
                        </b-media>
                    </b-col>


                    <b-col v-if="project.usoDelSueloCertificateUrl" md="2" lg="2" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0">
                        <b-media no-body>
                        <b-media-aside class="mr-2">
                            <b-avatar class="b-avatar badge-light-primary rounded-circle" button @click="openDocument(project.usoDelSueloCertificateUrl)" size="48" variant="light-primary">
                            <feather-icon size="24" icon="FileIcon"/>
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h5 class="font-weight-bolder mb-0">Certificado Uso del Suelo</h5>
                            <b-card-text @click="openDocument(project.usoDelSueloCertificateUrl)" class="font-small-3 mb-0 sosty-pointer">Descargar</b-card-text>
                        </b-media-body>
                        </b-media>
                    </b-col>
                    <b-col v-if="project.registroSanitarioUrl" md="2" lg="2" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0">
                        <b-media no-body>
                        <b-media-aside class="mr-2">
                            <b-avatar class="b-avatar badge-light-primary rounded-circle" button @click="openDocument(project.registroSanitarioUrl)" size="48" variant="light-primary">
                            <feather-icon size="24" icon="FileIcon"/>
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h5 class="font-weight-bolder mb-0">Registro Sanitario</h5>
                            <b-card-text @click="openDocument(project.registroSanitarioUrl)" class="font-small-3 mb-0 sosty-pointer">Descargar</b-card-text>
                        </b-media-body>
                        </b-media>
                    </b-col>
                    <b-col v-if="project.ultimoSoporteVacunacionUrl" md="2" lg="2" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0">
                        <b-media no-body>
                        <b-media-aside class="mr-2">
                            <b-avatar class="b-avatar badge-light-primary rounded-circle" button @click="openDocument(project.ultimoSoporteVacunacionUrl)" size="48" variant="light-primary">
                            <feather-icon size="24" icon="FileIcon"/>
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h5 class="font-weight-bolder mb-0">Ultimo Soporte Vacunación</h5>
                            <b-card-text @click="openDocument(project.ultimoSoporteVacunacionUrl)" class="font-small-3 mb-0 sosty-pointer">Descargar</b-card-text>
                        </b-media-body>
                        </b-media>
                    </b-col>
                    <b-col v-if="project.contratoDeArriendoUrl" md="2" lg="2" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0">
                        <b-media no-body>
                        <b-media-aside class="mr-2">
                            <b-avatar class="b-avatar badge-light-primary rounded-circle" button @click="openDocument(project.contratoDeArriendoUrl)" size="48" variant="light-primary">
                            <feather-icon size="24" icon="FileIcon"/>
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h5 class="font-weight-bolder mb-0">Contrato de Arriendo</h5>
                            <b-card-text @click="openDocument(project.contratoDeArriendoUrl)" class="font-small-3 mb-0 sosty-pointer">Descargar</b-card-text>
                        </b-media-body>
                        </b-media>
                    </b-col>
                    <b-col v-if="project.determinantesRiesgosAmbientalesYSocialesUrl" md="2" lg="2" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0">
                        <b-media no-body>
                        <b-media-aside class="mr-2">
                            <b-avatar class="b-avatar badge-light-primary rounded-circle" button @click="openDocument(project.determinantesRiesgosAmbientalesYSocialesUrl)" size="48" variant="light-primary">
                            <feather-icon size="24" icon="FileIcon"/>
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h5 class="font-weight-bolder mb-0">Determinantes Riesgos Ambientales y Sociales</h5>
                            <b-card-text @click="openDocument(project.determinantesRiesgosAmbientalesYSocialesUrl)" class="font-small-3 mb-0 sosty-pointer">Descargar</b-card-text>
                        </b-media-body>
                        </b-media>
                    </b-col>
                    <!-- <b-col v-if="project.contratoPrestacionServiciosUrl" md="2" lg="2" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0">
                        <b-media no-body>
                        <b-media-aside class="mr-2">
                            <b-avatar class="b-avatar badge-light-primary rounded-circle" button @click="openDocument(project.contratoPrestacionServiciosUrl)" size="48" variant="light-primary">
                            <feather-icon size="24" icon="FileIcon"/>
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h5 class="font-weight-bolder mb-0">Contrato Prestación de Servicios al Joint Venture</h5>
                            <b-card-text @click="openDocument(project.contratoPrestacionServiciosUrl)" class="font-small-3 mb-0 sosty-pointer">Descargar</b-card-text>
                        </b-media-body>
                        </b-media>
                    </b-col> -->
                    <b-col md="2" lg="2" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0">
                        <b-media no-body>
                        <b-media-aside class="mr-2">
                            <b-avatar class="b-avatar badge-light-primary rounded-circle" button @click="openDocument('https://drive.google.com/file/d/1GE8tWPk7hXn-LHOq9XKQ631mynA2Sili/view?usp=sharing')" size="48" variant="light-primary">
                            <feather-icon size="24" icon="FileIcon"/>
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h5 class="font-weight-bolder mb-0">Condicionado Seguro Bovino y Bufalino</h5>
                            <b-card-text @click="openDocument('https://drive.google.com/file/d/1GE8tWPk7hXn-LHOq9XKQ631mynA2Sili/view?usp=sharing')" class="font-small-3 mb-0 sosty-pointer">Descargar</b-card-text>
                        </b-media-body>
                        </b-media>
                    </b-col>
                    <b-col v-if="project.suraCotizacionSeguroUrl" md="2" lg="2" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0">
                        <b-media no-body>
                        <b-media-aside class="mr-2">
                            <b-avatar class="b-avatar badge-light-primary rounded-circle" button @click="openDocument(project.suraCotizacionSeguroUrl)" size="48" variant="light-primary">
                            <feather-icon size="24" icon="FileIcon"/>
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h5 class="font-weight-bolder mb-0">Cotización Seguro Sura</h5>
                            <b-card-text @click="openDocument(project.suraCotizacionSeguroUrl)" class="font-small-3 mb-0 sosty-pointer">Descargar</b-card-text>
                        </b-media-body>
                        </b-media>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
      </b-col>   
    </b-row>
</div>
  
</template>

<script>

import axios from '@axios'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import {
  BCard, BCardText, BButton, BRow, BCol, BImg, BCardBody, BCardTitle, BCardSubTitle, BLink, BProgress, BProgressBar, BMedia, BAvatar, BMediaAside, BMediaBody, BEmbed, 
  BCardHeader, BCarousel, BCarouselSlide, BModal, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BAlert, BTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import BCardCode from '@core/components/b-card-code'
import Cleave from 'vue-cleave-component'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import RisksModalComponent from '@core/components/RisksModalComponent'

const $trackBgColor = '#EBEBEB'

export default {
  components: {
    RisksModalComponent,
    AppCollapse,
    AppCollapseItem,
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BCardHeader,
    BRow,
    BCol,
    BLink,
    BImg,
    BProgress,
    BProgressBar,
    StatisticCardHorizontal,
    StatisticCardVertical,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BCardCode,
    BEmbed,
    BCarousel, 
    BCarouselSlide,
    BModal,
    BFormGroup, 
    BFormInput, 
    BInputGroupAppend, 
    BInputGroup,
    Cleave,
    VueApexCharts,
    BAlert,
    BTooltip,
    
  },
  data() {
    return {
      processing: false,
      isLoggedIn: false,
      project: {},
      producer: {},      
      projectID: null,
      projectCode: null,
      amountToInvest: '0',
      amountToReceive: '0',
      projectUrl: window.location.href,
      userType: '',
      pesoFinal: null, 
      pesoInicial: null, 
      totalProjectWeigthGain: null,
      currentAmountOfCattles: null,
      minimumParticipation: null,
      kilogramVal: null,
      freightPerKilogram: null,
      insuranceValuePerKilogram: null,
      sostyCommission: null,
      simulateTotalToInvest: null,
      simulateTotalToReceive: null,
      investmentOfPercentage: null,
      totalGainPercent: null,
      projectHaveReachedInvestment: false,
      NumeroKilogramosComprarSimulated: 0,            
      isBlockedForInvestment: null,
      totalPriceOfKilogramsSimulated: null, 
      tasaSostyComisionMandatoSimulated: null,
      minimalInvestment:700000,
      max: 100,
      minBar: 0,
      minRequiredToStart: 0,
      minKgsRequested: 0,
      minCattlesRequired:25,
      simulationExecutionCounter: 0,
      simulatedRevenueYear1: 0,
      simulatedRevenueYear2: 0,
      simulatedRevenueYear3: 0,
      simulatedRevenueYear4: 0,
      simulatedRevenueYear5: 0,
      simulatedRevenueCowsAndBulls: 0,

      options: {
          date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
          },
          number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          },
      },  
      chartOptions: {
          chart: {
            type: 'line',
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          grid: {
            borderColor: $trackBgColor,
            strokeDashArray: 5,
            xaxis: {
              lines: {
                show: true,
              },
            },
            yaxis: {
              lines: {
                show: true,
              },
            },
            padding: {
              top: -30,
              bottom: -3,
            },
          },
          stroke: {
            width: 3,
          },
          colors: [$themeColors.success],
          markers: {
            size: 2,
            colors: $themeColors.success,
            strokeColors: $themeColors.success,
            strokeWidth: 2,
            strokeOpacity: 1,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [
              {
                seriesIndex: 0,
                dataPointIndex: 5,
                fillColor: '#ffffff',
                strokeColor: $themeColors.success,
                size: 5,
              },
            ],
            shape: 'circle',
            radius: 2,
            hover: {
              size: 3,
            },
          },
          xaxis: {
            title: {
              text: 'Meses',  
              style: {
                fontSize: '12px',
                color: '#b9b9c3',
                font: 'Arial',
                fontWeight: 'normal',
              },          
            },  
            labels: {
              show: true,
              style: {
                fontSize: '0px',
              },
            },
            axisBorder: {
              show: true,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            title: {
              text: 'Kilos',
              style: {
                fontSize: '12px',
                color: '#b9b9c3',
                fontWeight: 'normal',
              },  
            },
            labels: {
              show: true,
              style: {
                fontSize: '0px',
              },
            },            
            axisBorder: {
              show: true,
            }, 
          },
          tooltip: {
            x: {
              show: false,
            },
          },
      },
      chartOptions2: {
          chart: {
            type: 'bar',
            stacked: true,
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          grid: {
            borderColor: $trackBgColor,
            strokeDashArray: 5,
            xaxis: {
              lines: {
                show: true,
              },
            },
            yaxis: {
              lines: {
                show: true,
              },
            },
            padding: {
              top: -30,
              bottom: -3,
            },
          },
          stroke: {
            width: 3,
          },
          colors: [$themeColors.success, $themeColors.warning, $themeColors.info],
          markers: {
            size: 2,
            colors: $themeColors.success,
            strokeColors: $themeColors.success,
            strokeWidth: 2,
            strokeOpacity: 1,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [
              {
                seriesIndex: 0,
                dataPointIndex: 5,
                fillColor: '#ffffff',
                strokeColor: $themeColors.success,
                size: 5,
              },
            ],
            shape: 'circle',
            radius: 2,
            hover: {
              size: 3,
            },
          },
          plotOptions: {
            bar: {
              columnWidth: '40%',
            },
          },          
          xaxis: {
            title: {
              text: 'Año',  
              style: {
                fontSize: '12px',
                color: '#b9b9c3',
                font: 'Arial',
                fontWeight: 'normal',
              },          
            },  
            labels: {
              show: true,
              style: {
                fontSize: '0px',
              },
            },
            axisBorder: {
              show: true,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            title: {
              text: 'Ingresos',
              style: {
                fontSize: '12px',
                color: '#b9b9c3',
                fontWeight: 'normal',
              },  
            },
            labels: {
              show: true,
              style: {
                fontSize: '0px',
              },
            },            
            axisBorder: {
              show: true,
            }, 
          },
          tooltip: {
            x: {
              formatter: function(value, series) {
                // use series argument to pull original string from chart data
                return "Año " + value;
              },
            },
            y: {
              formatter: function(value, series) {
                // use series argument to pull original string from chart data
                return Math.round(value).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") + " COP";
              }
            }
          },
          dataLabels: {
            enabled: false,
          },
      },
      series: [
        {
          name: 'Peso Kg',
          data: [],
        },
      ],
      series2: [
        {
          name: 'NeoGanaderos',
          data: [],
        },
        {
          name: 'Productor',
          data: [],
        },
        {
          name: 'Sosty',
          data: [],
        },
      ],
      isBreedingProject: false,
    }
  },
  created() {
    if(this.$route != null && this.$route.query != null && this.$route.query.projectID != null) {
      this.projectID = this.$route.query.projectID;
      this.GetProjectPublicById();
    } else if(this.$route != null && this.$route.query != null && this.$route.query.projectCode != null) {
      this.projectCode = this.$route.query.projectCode;
      this.GetProjectPublicByCode();
    } 
    var userData = getUserData();
    if(userData){
      this.isLoggedIn = true;
      this.userType = userData.userType;
    }
    
  },
  watch:{    
    simulateTotalToInvest: function(val) { this.simulateTotalToInvest = val; },
    minBar: function(val) { this.minBar = val; },
  },
  methods: {
    GetProjectPublicById() {
        this.processing = true;
        axios.get('/Project/GetProjectPublicById', { params: { projectID: this.projectID }})        
        .then(response => {
          this.processing = false;
          this.project = response.data;
          this.isBlockedForInvestment = this.project.isBlockedForInvestment;

          this.project.projectProgres = parseFloat(this.project.projectProgres)
          this.minKgsRequested = parseFloat(this.project.investmentRequired)*parseFloat(this.minCattlesRequired)/100          
          this.minRequiredToStart = (parseFloat(parseFloat(this.minKgsRequested))*100/Math.round(parseFloat(this.project.investmentRequired)).toFixed(2))          
          this.minBar = parseFloat(this.minRequiredToStart).toFixed(2) - parseFloat((this.project.projectProgres));
          this.isBreedingProject = this.project.projectType === 'Cria'
          
          this.UpdateActiveCampaignDeal(0, '3')    
          this.variables()
          this.calculateChartPoints();     
          this.simulateTotalToInvest = 5000000;
          this.simulateInvestmentResults()
        })
        .catch(error => {
          this.processing = false;
           
        })
    }, 
    GetProjectPublicByCode() {
        this.processing = true;
        axios.get('/Project/GetProjectPublicByCode', { params: { projectCode: this.projectCode }})        
        .then(response => {
          this.processing = false;
          this.project = response.data;
          this.isBlockedForInvestment = this.project.isBlockedForInvestment;

          this.project.projectProgres = parseFloat(this.project.projectProgres)
          this.minKgsRequested = parseFloat(this.project.investmentRequired)*parseFloat(this.minCattlesRequired)/100          
          this.minRequiredToStart = (parseFloat(parseFloat(this.minKgsRequested))*100/Math.round(parseFloat(this.project.investmentRequired)).toFixed(2))          
          this.minBar = parseFloat(this.minRequiredToStart).toFixed(2) - parseFloat((this.project.projectProgres));
          this.isBreedingProject = this.project.projectType === 'Cria'
          
          this.UpdateActiveCampaignDeal(0, '3')    
          this.variables()
          this.calculateChartPoints();     
          this.simulateTotalToInvest = 5000000;
          this.simulateInvestmentResults()
        })
        .catch(error => {
          this.processing = false;
           
        })
    }, 
    calculateChartPoints() {
      if(this.project.projectType == 'Levante' || this.project.projectType == 'Ceba') {          
        var durationInMonths = parseInt(this.project.projectDuration); 
        var totalStartWeight = parseInt(this.project.investmentRequired)
        var totalEndWeight = parseInt(this.project.totalProjectWeigthGain);      
        var gainPerProject = parseInt(totalEndWeight - totalStartWeight)
        var gainPerMonth = parseInt(gainPerProject/durationInMonths)
        var gainPerDay = parseInt(gainPerMonth/30)      
        var gainPerDayPerCattle = parseInt(gainPerDay/this.project.amountOfCattles*1000)  
        if(gainPerDay && this.project.amountOfCattles ){
          this.project.cattleWeightAverageGain = parseInt(this.project.cattleWeightAverageGain)
        }else{
          this.project.cattleWeightAverageGain = 0
        }                        
        var totalGainOfProject = gainPerMonth*durationInMonths;            
        var initialWeight = totalEndWeight - totalGainOfProject; 


        var chartPoints = []
        var currentWeight = parseInt(initialWeight);
        for (var i = 0; i < durationInMonths+1; i++) {
          chartPoints.push(currentWeight)
          currentWeight = currentWeight + gainPerMonth;
        }

        this.series[0].data = chartPoints;
        this.$refs.baseLineChart.updateSeries([{
          data: this.series[0].data,
        }], false, true);
      } 
    }, 
    openDocument(fileUrl) {
        window.open(fileUrl, '_blank');
    },   
    openProducerPublicProfile() {
      let route = this.$router.resolve({path: 'producer-public-profile?producerID=' + this.project.producerID});
      window.open(route.href, '_blank');
    },
    goToInvest() {
      if(!this.isLoggedIn){
        this.$router.replace({path: 'login?redirectToProjectCode=' + this.project.projectCode});
      }else{
        this.$router.replace({path: 'invest-in-project?projectCode=' + this.project.projectCode + '&amountToInvest=' + this.simulateTotalToInvest});
      }      
    },
    goToLoginWithRedirection() {
      this.$router.replace({path: 'login?redirectToProjectCode=' + this.project.projectCode});
    },
    goToRegisterWithRedirection() {
      let route = this.$router.replace({path: 'register?redirectToProjectCode=' + this.project.projectCode + '&userType=Inversionista'});
    },   
    enableInvestorProfileForProducer(){      
      var userData = getUserData();      
      var userID = userData.userID;                  
      axios.get('/User/EnableInvestorProfileForProducer', { params: { userID }})
      .then(this.goToInvest())   
    },
    variables(){
      this.totalPricePerKilogram = this.project.totalPricePerKilogram;
      this.projectHaveReachedInvestment = parseFloat(this.project.investmentCollected) >= parseFloat(this.project.investmentRequired);
      if(this.project.investmentCollected)
        this.currentAmountOfCattles = (parseFloat(this.project.investmentCollected) * parseFloat(this.project.amountOfCattles) / parseFloat(this.project.investmentRequired)).toFixed(2);
      else
        this.currentAmountOfCattles = 0;
    },
    simulateInvestmentResults(){ 
      var monthlyGain = parseFloat(this.project.projectProfitability)/12;        
      this.totalGainPercent = this.project.projectDuration * monthlyGain
      this.simulateTotalToReceive = this.simulateTotalToInvest + this.simulateTotalToInvest*this.totalGainPercent/100;

      this.simulationExecutionCounter++;
      if(this.simulationExecutionCounter == 3)
      {
        this.UpdateActiveCampaignDeal(0, '4')    
      }
    },
    UpdateActiveCampaignDeal(status, stage){
        var userData = getUserData();
        if(userData) axios.get('/User/UpdateActiveCampaignDeal', { params: { userID: userData.userID, status: status, stage: stage, title: this.project.projectCode } })
    },
    showRisksModal(){      
    this.$refs['modal-risk'].show()
    },
    hideRisksModal(){
      this.$refs['modal-risk'].hide()
    }  
  },
  directives: {
    Ripple,
  },
  setup() {
    const openPorjectLocationInMap = iconName => {      
      window.open("https://www.google.com/maps/@4.6951311,-74.0616453,15z");
    }
    return {
      openPorjectLocationInMap,
    }
  },
}
</script>       
